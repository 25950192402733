.bellIconWrapper {
  position: relative;
  cursor: pointer;
}

.notification-read-dot {
  background-color: #ffffff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition: all ease-in-out 0.3s;
}
.no-notifications {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  color: #b3b3b3
}

.notification-unread-dot{
  background-color: #66a3a6;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition: all ease-in-out 0.3s;
}

.notification-countdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.notification-countdown > p {
  width: max-content !important;
}

.notification-read-dot {
  background-color: #ffffff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition: all ease-in-out 0.3s;
}
.no-notifications {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  color: #b3b3b3
}

.notification-unread-dot{
  background-color: #66a3a6;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition: all ease-in-out 0.3s;
}

.notification-countdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.notification-countdown > p {
  width: max-content !important;
}
.notificationNumberDiv {
  width: 19px;
  height: 19px;
  background-color: #66a3a6;
  border-radius: 10px;
  position: absolute;
  bottom: 53%;
  left: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notificationNumberDiv span {
  /* padding: 0 5px 0; */
  color: #fff;
  font-size: 15px;
}
/* notifications panel */
.notificationTextWrapper {
  position: absolute;
  top: 130px;
  right: 15%;
  width: 35.813rem;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: opacity 0.5s, max-height 0.5s;
}
.notificationTextWrapper.show {
  opacity: 1;
  max-height: 500px;
  z-index: 111;
  /* overflow: auto; */
}
/* check this css for rounded profile image */
.notificationProfileImg {
  border-radius: 50%;
  height: 4.375rem;
  width: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
/* check this css for rounded profile image */
.notificationTextWrapper h3 {
  font-family: Lexend;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #66a3a6;
  margin: 0 0;
  padding: 5px 0;
}

.notificationClass {
  display: flex;
  gap: 20px;
}
.notificationClass {
  display: flex;
  gap: 20px;
  padding: 12px 20px;
  border-bottom: 1px solid #b3b3b3;
}
.notificationDetails h2 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0;
}
.notificationTxtClass {
  display: flex;
  align-items: center;
  gap: 25px;
}

.notificationDetails p {
  font-family: Lexend;
  font-size: 18px;
  font-weight: 300;
  margin: 0 0 5px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width:20.563rem;
  position: relative;
}
/* added */
.messageDiv p:nth-child(2) {
  width:2rem;
  padding: 0 30px 0 0;
}
.messageDiv {
  display: flex;
  align-items: flex-start;
}
.svgDrawer {
  cursor: pointer;
}
/*  */
.notificationTxt p::after {
  content: '...'; /* Ellipsis indicator */
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fff; /* Match background color */
  padding: 0 4px; /* Add padding for better appearance */
}

.notificationDetails span {
  font-family: Lexend;
  font-size: 18px;
  font-weight: 300;
  color: #b3b3b3;
  padding-right: 15px;
}
.notificationTimeClass {
  display: flex;
  gap: 10px;
}
.notifWatch {
  display: flex;
}
.notificationTimeClass p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  color: #b3b3b3;
}

.notificationTxt {
  display: flex;
  gap: 10px;
}

.notifi-container {
  overflow: auto;
  max-height: 425px;
}
/* Keyframes for sliding to the right and fading out */
@keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

/* Add fading in effect for notifications */
.notificationClass {
  animation: fadeIn 0.3s ease-in-out;
}

/* Class to trigger sliding to the right and fading out */
.notificationClass.fading-out {
  animation: slideOutRight 0.5s ease-in-out forwards;
}



@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.notification-clear-all{
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lexend;
  font-size: 18px;
  font-weight: 300;
  color: #66a3a6;
  cursor: pointer;
  border-top: 1px solid #b3b3b3;
}

.notification-img {
  width: 100%;
  height: 100%;
}
/* css for drawer */
.drawer {
  position: fixed;
  bottom: 20px; 
  right: 20px; 
  width: 300px; 
  background-color: #fff;
  /* border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  padding: 10px;
  border-radius: 4px;
  animation: slideIn 0.5s forwards; /* Animation for sliding in */
  display: none; /* Initially hidden */
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.drawer.showDrawer {
  display: block; /* Show the drawer */
}

/* css for draweer ends */

@supports (-moz-appearance: none) {
  .notificationNumberDiv{
    bottom: 23px;
  }
}
/* Css for animation */
@keyframes appear {
  0%{
    opacity: 0;
    transform: translateY(-10px);
  }
}

.notificationTextWrapper.show{
  animation: appear 350ms ease-in 1;
  /* overflow: auto; */
}
/* Css for animation ends*/

/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1280px) {
  .notificationNumberDiv{
    bottom: 23px;
  }
  .notificationNumberDiv span{
    padding: 0 6px 0;
    font-size: 13px;
  }
  .notificationClass{
    gap: 10px;
  }
  .notificationTextWrapper {
    right: 10%;
  }
}

@media only screen and (max-width: 450px){

  .notificationNumberDiv{
    left: 50%;
    top: -2px;
  }

  .notificationTextWrapper{
    right: 12px;
    width: 93%;
    top:200px;
  }

 .notificationProfileImg{
    height: 41px;
    width: 41px;
  }

  .notificationClass{
    height: 50px;
  }

  .notificationDetails{
    width: 263px;
    height: 39px;
  }

  .notificationDetails h2{
    font-size: 14px;
    font-weight: 400;
  }

  .notificationDetails p{
    font-size: 12px;
    font-weight: 300;
  }

  .notifWatch svg{
    width: 11px;
    height: 11px;
    position: relative;
    bottom: 2px;
  }
  .notifi-container{
    max-height: 535px;
  }
  .notificationTimeClass{
    gap: 5px;
    align-items: center;
  }
  .svgDrawer {
    position: relative;
    bottom: 2px;
}

}